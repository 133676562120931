import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/src/locale/pt';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#3B495B',
        secondary: '#28253f',
        accent: '#fce4e8',
        error: '#e63946',
        info: '#0d7abd',
        success: '#43aa8b',
        warning: '#FFC107',
        background: '#f4f4f4',
        textPrimary: '#3B495B',
        primaryRH: '#5454a4',
        newPrimary: '#6428bd',
        newSecondary: '#08d7d7',
        newAccent: '#5ee6be',
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: { iconfont: 'fa' },
});
