/* eslint-disable import/no-cycle */
<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="120">
        <div>Verificando autorizações...</div>
      </v-progress-circular>
    </v-overlay>

    <div v-if="!loading">
      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
          <v-icon large color="primary">fas fa-lock</v-icon>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
          <h1 class="text--primary">Acesso Negado</h1>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
        <p>Você não pode acessar esta funcionalidade.</p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import UserService from '@/services-http/security/UserService';
import UserUtils from '@/shared/utils/user-utils';

export default {
  name: 'Forbidden',
  data: () => ({
    dataRoute: null,
    loading: true,
  }),
  methods: {
    async loadUserSessionData() {
      await this.userService.GetCurrent().then((response) => {
        const session = response.data;
        if (session) {
          const userSessionStorage = {
            authenticated: session.authenticated,
            authorities: session.authorities,
            user: {
              // eslint-disable-next-line max-len
              name: session.principal && session.principal.user ? session.principal.user.name : null,
              email: session.name,
              // eslint-disable-next-line max-len
              financialGroupId: session.principal && session.principal.user ? session.principal.user.financialGroupExternalID : null,
              // eslint-disable-next-line max-len
              appCode: session.principal && session.principal.user ? session.principal.user.appCode : null,
            },
          };
          this.userUtils.createUserSessionStorage(userSessionStorage);
        }
      }).finally(() => {
        if (this.dataRoute != null && this.dataRoute.meta && this.dataRoute.path) {
          if (this.userUtils.hasAuthorityToAccess(this.dataRoute.meta)) {
            const query = { ...this.dataRoute.query };
            delete query.token;
            this.$router.push({ path: this.dataRoute.path, query: { ...query } });
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      });
    },
  },
  beforeCreate() {
    this.userUtils = new UserUtils();
    this.userService = new UserService();
  },
  async mounted() {
    this.dataRoute = await JSON.parse(sessionStorage.getItem('@route'));
    await this.loadUserSessionData();
  },
};
</script>
